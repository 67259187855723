import p3_nf from './assets/p3_nf.png'
import p1_yt from './assets/p1_yt.png'
import p2_hms from './assets/p2_hms.png'
// import lessonify from './assets/lessonify.png'
import mme from './assets/mme.png'
import portf from './assets/portf.png'

const contentCard = [
    {
        id: 1,
        title: 'YT Clone',
        body1: '- Developed a YouTube replica using React with Redux for state management, implementing advanced features including Infinity loading, Shimmer effect, and seamless rendering of multiple data from various APIs.',
        body2: '- Implemented error handling with dedicated Error Pages, integrated Search, Live Chats, and a Comments Section for enhanced user engagement, alongside offline-online indicators.',
        image: p1_yt,
        tech: `#ReactJS #Redux #TypeScript #Tailwind`,
        linkgithub:'https://github.com/Himanshu-0104/ytClone_ReactApp',
        linkdemo: 'https://yt.hydevs.online/'
        },
        {
        id: 2,
        title: 'MediSys-Database (HMS)',
        body1: "- Designed and implemented a comprehensive SQL database for a Hospital Management System. Developed complex queries for managing and analyzing hospital data.",
        body2: "- Created tables for admin, appointment, doctors, patient, userlog, doctorslog and medicalhistory.",    
        image: p2_hms,
        tech: '#SQL #PostgreSQL #Analytics #PHP',
        linkgithub:'https://github.com/Himanshu-0104/MediSys-Database-HMS',
        linkdemo: 'https://himanshu-0104.github.io/MediSys-Database-HMS/'
        },
        {
        id: 4,
        title: 'Netflix Clone',
        body1: '- Responsive UI clone of Netflix which includes tools such as Hooks, React Router, Redux Toolkit.',
        image: p3_nf,
        tech: `#ReactJS  #SCSS`,
        linkgithub:'https://github.com/Himanshu-0104/NetflixClone',
        linkdemo: 'https://nf.hydevs.online/'
        },
        {
        id: 6,
        title: 'MakesMathEasy',
        body1: "- Contributed in a web-app which aims at providing solutions to various Math problems along with steps. Made 100+ Successfully merged Pull Requests.",
        image: mme,
        tech: `#JAVASCRIPT #JQUERY #BOOTSTRAP #LATEX`,
        linkgithub:'https://github.com/makesmatheasy/makesmatheasy',
        linkdemo: 'https://makesmatheasy.github.io/makesmatheasy/'
        },
        {
        id: 5,
        title: 'Portfolio',
        body1: "- My personal portfolio website built with React with smooth scroll with modern look to showcase my projects and accomplishments.",
        image: portf,
        tech: `#ReactJS #StyledComponents #AOS #Animations`,
        linkgithub:'https://github.com/Himanshu-0104/hydevs',
        linkdemo: 'https://hydevs.online'
        }
    ]
    
    export default contentCard
