import React from "react";
// import ReactDOM from "react-dom";
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";




import "./timeline.css";


const H2 = styled.h2`
    font-weight: 900;
    font-size: 2.2rem;
    color: #dfdfdf;
    text-align: center;
    margin-bottom: 2em;
    margin-top: 4em;
@media (max-width: 768px){
        margin-top: 2vh;
        margin-bottom: 7vh;
        font-size: 5rem;
    }
@media (max-width: 480px){
        margin-top: 10vh;
        margin-bottom: 7vh;
        font-size: 3.5rem;
    }  
`

const H3 = styled.h3`
    font-weight: 800;
    font-size: 1.5rem;
    color: #dfdfdf;
    text-align: left;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    @media (max-width: 768px){
        margin-top: 2vh;
        margin-bottom: 7vh;
        font-size: 3rem;
    }
    @media (max-width: 480px){
        margin-top: 10vh;
        margin-bottom: 7vh;
        font-size: 1.5rem;
}  
`

const HI = styled.h2`
    font-size: 1rem;
    color: #dfdfdf;
    text-align: left;
    margin-bottom: 0.5em;
    margin-top: 0.5em;
    @media (max-width: 768px){
        margin-top: 2vh;
        margin-bottom: 7vh;
        font-size: 3rem;
    }
    @media (max-width: 480px){
        margin-top: 10vh;
        margin-bottom: 7vh;
        font-size: 1.5rem;
}  `

const H4 = styled.h4`
    font-size: 1.1rem;
    font-weight: 400;
    color: #dfdfdf;
    @media (max-width: 768px){
        font-size: 1.8rem;
    }
    @media (max-width: 480px){
        font-size: 1rem;
    }
`

// const pr = styled.p`
//     font-size: 1.1rem;
//     font-weight: 400;
//     color: #343a40;
//     @media (max-width: 768px){
//         font-size: 1.8rem;
//     }
//     @media (max-width: 480px){
//         font-size: 1rem;
//     }
// `

const Qualification = () => {
  return (
    <div
      id="qualification"
    >
        <H2>Qualification</H2>
      
      <VerticalTimeline>
          <VerticalTimelineElement
            className="custom-line"
            contentStyle={{ background: '#171717', color: '#dfdfdf' }} 
            iconStyle={{ background: "#9241d5", color: '#dfdfdf'}}>
            <H3>Education</H3>
            <div className="container">

                <div className="timeline-block timeline-block-right">
                    <div className="marker active"><i className="fa fa-check active" aria-hidden="true"></i></div>
                    <div className="timeline-content">
                        <HI>2021-2025</HI><p>B.Tech in Computer Science and Engineering <br/> UIET Chandigarh PU <br/> 8.18 CGPA</p>
                    </div>
                </div>

                <div className="timeline-block timeline-block-left">
                    <div className="marker active"><i className="fa fa-check" aria-hidden="true"></i></div>
                    <div className="timeline-content">
                        <HI>2019-2020</HI><p>92% PCM, <br/>Class 12 CBSE</p>
                    </div>
                </div>

                <div className="timeline-block timeline-block-right active">
                    <div className="marker active"><i className="fa fa-check active" aria-hidden="true"></i></div>
                    <div className="timeline-content">
                        <HI>2017-2018</HI><p>81.8%, Class 10 CBSE</p>
                        
                    </div>
                </div>
            </div>
            </VerticalTimelineElement>

            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: '#171717', color: '#dfdfdf' }} 
                iconStyle={{ background: "#9241d5", color: '#dfdfdf'}}>
                <H3>Experience</H3>
                <div className="container">
                    <div className="timeline-block timeline-block-right">
                        <div className="marker active"><i className="fa fa-check active" aria-hidden="true"></i></div>
                        <div className="timeline-content">
                            <HI>Software Development Intern</HI>
                            <Link to={{ pathname: "https://fabulousmedia.in/" }} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', fontWeight: 'bold'}}><H4>Fabulous Media</H4></Link>
                            <h4>June 2022 - August 2022</h4>
                            <p>- Worked as a Frontend Web Developer, Proficiently producing user-friendly and responsive websites both collaboratively within a team and individually.<br/>- Collaborated with the Frontend Development team to create and implement responsive websites like Health Upwardly Mobile, Halo Homes and Fabulous Media, that ensuring optimal user experience.<br/>- Led impactful solo projects, merging innovative design with SEO strategies to elevate users engagement. These initiatives significantly contributed to a remarkable 56 percent increase in company revenue.</p>
                        </div>
                    </div>
                    <div className="timeline-block timeline-block-right">
                        <div className="marker active"><i className="fa fa-check active" aria-hidden="true"></i></div>
                        <div className="timeline-content">
                        <HI>ARTIFICIAL INTELLIGENCE</HI>
                        <Link to={{ pathname: "https://dicpu.in/" }} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', fontWeight: 'bold'}}><H4>MDaRT DIC Chandigarh</H4></Link>
                        <h4>June 2023 - July 2023</h4>
                        <p>- Developed a skull reconstruction model using a 3D U-Net architecture, leveraging a comprehensive dataset sourced from the MUG500 repository consisting of NRRD images.<br/>- Implemented advanced algorithms and 3D modeling techniques to achieve exceptional accuracy of 89% in restoring skull structures, resulting in enhanced surgical planning and patient outcomes.<br/>- Proficient in deep learning techniques, specializing in medical image analysis and data preprocessing, with a proven track record of exceptional performance <br/></p>
                        </div>
                    </div>
                    <div className="timeline-block timeline-block-right">
                        <div className="marker active"><i className="fa fa-check active" aria-hidden="true"></i></div>
                        <div className="timeline-content">
                        <HI>Product Developer</HI>
                        <Link to={{ pathname: "https://inncircles.com/#home" }} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', fontWeight: 'bold'}}><H4>Inncircles Technologies</H4></Link>
                        <h4>July 2023 - April 2024</h4>
                        <p>-Involved in dynamic contributions to web and mobile📱 development projects, actively influencing and improving project outcomes. <br/>- Developed multiple shared components to simplify tasks for fellow developers, boosting efficiency and teamwork. <br/>- Enhanced product performance through significant contributions to backend development, optimizing essential components for improved efficiency. <br/>- Implemented data security for the company’s product to provide role-based access control. </p>
                        </div>
                    </div>
                    <div className="timeline-block timeline-block-right">
                        <div className="marker active"><i className="fa fa-check active" aria-hidden="true"></i></div>
                        <div className="timeline-content">
                        <HI>Technical Intern</HI>
                        <Link to={{ pathname: "https://www.att.com/" }} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', fontWeight: 'bold'}}><H4>AT & T</H4></Link>
                        <h4>June 2024 - July 2024</h4>
                        <p>-Gained experience with Git version control contributing to the development and maintenance of code repositories.. <br/>- Worked on the application dashboard, making it more user-friendly and improving its overall functionality📱</p>
                        </div>
                    </div>
                </div>
            
            </VerticalTimelineElement>

            <VerticalTimelineElement
                className="vertical-timeline-element--work"
                contentStyle={{ background: '#171717', color: '#dfdfdf' }} 
                iconStyle={{ background: "#9241d5", color: '#dfdfdf'}}>
                <H3>Achievements</H3>
                <div className="container">
                    {/* <div className="timeline-block timeline-block-right">
                        <div className="marker active"><i className="fa fa-check active" aria-hidden="true"></i></div>
                        <div className="timeline-content">
                        <HI>Top Contributor GirlScript Summer of Code'21</HI><p>Among Top 10 contributors from 2000+ active contributors with 100+ PRs merged in a single Project. <br/>Received <a href = "https://drive.google.com/file/d/1K6tNIGgeX6yV4dluKSuwudEBs2KajkQg/view">Letter of Recommendation</a></p>
                        </div>
                    </div> */}
                    <div className="timeline-block timeline-block-right">
                        <div className="marker active"><i className="fa fa-check active" aria-hidden="true"></i></div>
                        <div className="timeline-content">
                        <h3 style={{color: "#888", fontSize: "1rem"}}>Solved more than 1250 problems on <Link to={{ pathname: "https://leetcode.com" }} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#9241d5' }}>Leetcode</Link> | <Link to={{ pathname: "https://codeforces.com/" }} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#9241d5' }}>Codeforces</Link> | <Link to={{ pathname: "https://www.codechef.com/" }} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#9241d5' }}>Codechef</Link> | <Link to={{ pathname: "https://www.geeksforgeeks.org/" }} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#9241d5' }}>GeeksForGeeks</Link> | <Link to={{ pathname: "https://www.interviewbit.com/" }} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#9241d5' }}>InterviewBit</Link>.</h3>
                        </div>
                    </div>
                    <div className="timeline-block timeline-block-right">
                        <div className="marker active"><i className="fa fa-check active" aria-hidden="true"></i></div>
                        <div className="timeline-content">
                        <h3 style={{color: "#888", fontSize: "1rem"}}>Expert on Codeforces with maximum rating 1642 and Qualified HackWithInfy 2024.</h3>
                        </div>
                    </div>
                    <div className="timeline-block timeline-block-right">
                        <div className="marker active"><i className="fa fa-check active" aria-hidden="true"></i></div>
                        <div className="timeline-content">
                        <h3 style={{color: "#888", fontSize: "1rem"}}>5 Star on Codechef with rating 2031 and Qualified Amazon ML Summer School 2024</h3>
                        </div>
                    </div>
                    <div className="timeline-block timeline-block-right">
                        <div className="marker active"><i className="fa fa-check active" aria-hidden="true"></i></div>
                        <div className="timeline-content">
                        <h3 style={{color: "#888", fontSize: "1rem"}}>Secured global ranks of 411 in Codeforces 2nd May 2024 contest.</h3>
                        </div>
                    </div>
                    <div className="timeline-block timeline-block-right">
                        <div className="marker active"><i className="fa fa-check active" aria-hidden="true"></i></div>
                        <div className="timeline-content">
                        <h3 style={{color: "#888", fontSize: "1rem"}}>Secured global ranks of 57 in Codechef Starters 139 on 19 June 2024 contest.</h3>
                        </div>
                    </div>
                </div>
            
            </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
}


export default Qualification;
